<section class="content-section bg-grey  padding">
    <div class="container">
        <div class="row content-wrap">
            <div class="col-lg-6 sm-padding">
                <div class="content-info wow fadeInRight" data-wow-delay="300ms">
                    <h2>Em desenvolvimento</h2>
                </div>
            </div>
        </div>
    </div>
</section>