import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private wowService: NgwWowService,@Inject(DOCUMENT)  private document: Document) {
    this.wowService.init();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.document.body.classList.remove('viewport-lg');
      this.document.body.classList.add('viewport-sm');
    } else {
      this.document.body.classList.remove('viewport-sm');
      this.document.body.classList.add('viewport-lg');
    }
  }
}
