import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-area',
  templateUrl: './customer-area.component.html',
  styleUrls: ['./customer-area.component.css']
})
export class CustomAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
