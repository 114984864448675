<section class="service-section bg-grey section-2 padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 sm-padding ">
                <div class="service-content wow fadeInLeft">
                    <h2 class="example-full-width">Perguntas frequentes - FAQ
                        <mat-form-field style="padding-left: 20px;">
                            <mat-icon matPrefix style="color: #00000054">search</mat-icon>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="">
                        </mat-form-field>
                    </h2>
                </div>
            </div>
        </div>
        <mat-accordion style="color: #fff">
            <mat-expansion-panel *ngFor="let faq of filteredFaqs; let indexFaq = index" hideToggle>
                <mat-expansion-panel-header [ngStyle]="{'background-color': indexFaq % 2 == 0 ? '#fff' : '#fff' }">
                    <mat-panel-title>
                        <h2>{{faq.group}}</h2>
                    </mat-panel-title>
                    <mat-panel-description style="justify-content: flex-end;">
                        <mat-icon>{{faq.icon}}</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-expansion-panel *ngFor="let answer of faq.answers; let indexAnswer = index">
                    <mat-expansion-panel-header>
                        <mat-panel-title
                            style='font-weight: bold; font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;'>
                            {{(indexFaq + 1)}}.{{(indexAnswer+ 1)}} - {{answer.question}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p style="padding-left: 20px; text-align: justify;" [innerHtml]="answer.answer"></p>
                </mat-expansion-panel>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>