<ngx-slick-carousel id="main-slider" class="dl-slider" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="single-slide">
        <div class="bg-img kenburns-top-right" style="background-image: url(../../../{{slide.img}});"></div>
        <div class="overlay"></div>
        <div class="slider-content-wrap d-flex align-items-center text-right">
            <div class="container">
                <div class="slider-content">
                </div>
            </div>
        </div>
    </div>
</ngx-slick-carousel>
<section class="content-section bg-grey padding">
    <div class="container">
        <div class="row content-wrap">
            <div class="col-lg-6 sm-padding">
                <div class="content-info wow fadeInRight" data-wow-delay="300ms">
                    <h2>A VSA Construtora</h2>
                    <p style="text-align: justify;">Em 2007, iniciamos nossas atividades no mercado da construção
                        civil do Distrito Federal.
                        Éramos poucos, contudo ágeis e produtivos.
                    </p>
                    <p style="text-align: justify;">Produtividade e agilidade
                        trouxeram bons resultados e, a cada obra entregue, mais clientes
                        satisfeitos. Clientes que tiveram papel muito importante divulgando nossa empresa. Por
                        consequência, o crescimento da empresa tornou-se necessário. O que levou a gerar mais
                        empregos, mais aperfeiçoamentos de práticas de trabalho, novas parcerias e diversificação
                        dos negócios.</p>
                    <p style="text-align: justify;">Devido ao volume e à velocidade das mudanças no mundo, a VSA
                        busca atualização e melhoria
                        continua para seguir construindo sonhos e agregando valores.
                        Muito prazer, somos a VSA!</p>
                </div>
            </div>
            <div class="col-lg-6 sm-padding wow fadeInLeft" data-wow-delay="100ms">
                <img class="box-shadow" src="../../../assets/img/team.jpeg" alt="img">
            </div>
        </div>
    </div>
</section>
<section class="service-section section-2 padding">
    <div class="dots"></div>
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-lg-12 sm-padding " style="background: url(../../../assets/img/bg-dots.png) 0 0 repeat;">
                <div class="service-content wow fadeInLeft">
                    <h2>O tripé da VSA</h2>
                    <div class="row">
                        <div class="col-md-4 padding-15">
                            <div class="service-item box-shadow wow fadeInUp" data-wow-delay="100ms">
                                <h3>Missão</h3>
                                <p>Entregar patrimônios com solidez e credibilidade, com o emprego de soluções
                                    construtivas eficientes.</p>
                            </div>
                        </div>
                        <div class="col-md-4 padding-15 offset-top">
                            <div class="service-item box-shadow wow fadeInUp" data-wow-delay="300ms">
                                <h3>Visão</h3>
                                <p>Garantir a satisfação e a consolidação das relações pessoais com os clientes,
                                    colaboradores e fornecedores; contribuir para o desenvolvimento econômico local;
                                    e ser reconhecida pela excelência de seus serviços e produtos.</p>
                            </div>
                        </div>
                        <div class="col-md-4 padding-15">
                            <div class="service-item box-shadow wow fadeInUp" data-wow-delay="400ms">
                                <h3>Valores</h3>
                                <p>Fundamentados no respeito às relações humanas, no uso consciente dos recursos, na
                                    credibilidade recíproca, no comprometimento com o resultado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content-section padding">
    <div class="container">
        <div class="row content-wrap">
            <div class="col-lg-12 sm-padding ">
                <div class="service-content wow fadeInLeft">
                    <h2>Por que contratar a VSA?</h2>
                </div>
            </div>
        </div>
        <section class="testimonial-section">
            <owl-carousel [options]="testimonialCarouselConfig"
                [carouselClasses]="['testimonial-carousel', 'box-shadow ', 'box-shadow owl-carousel']">
                <div class="item testi-item align-items-center" *ngFor="let testimonial of testimonials; let i = index">
                    <img src="{{testimonial.img}}" alt="img"
                        class="hidden-phone d-sm-none d-xs-none d-md-block d-lg-block">
                    <div class="testi-content">
                        <p class="text-justify" [innerHtml]="testimonial.testimonial">
                        </p>
                        <h3>{{testimonial.customer}}</h3>
                        <span>{{testimonial.from}}</span>
                    </div>
                    <i class="fa fa-quote-right"></i>
                </div>
            </owl-carousel>
        </section>
    </div>
</section>
<section class="service-section bg-grey section-2 padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 sm-padding ">
                <div class="service-content wow fadeInLeft">
                    <h2>O que fazemos?</h2>

                </div>
            </div>
        </div>
        <div class="row justify-content-center h-100">
            <div class="col-lg-6 sm-padding">

                <div class="row services-list">
                    <div class="col-md-6 padding-15" style="padding-top: 0px !important;">

                        <div class="service-item box-shadow wow fadeInUp" data-wow-delay="100ms"
                            onmouseover="document.getElementById('img-how-do-i-doing').style.backgroundImage = 'url(' + '../../../assets/img/obra-em-andamento/obra-em-andamento-3.jpg'+ ')'">
                            <i class="flaticon-loader"></i>
                            <h3>Construções</h3>
                            <p>Temos mais de 21.000 m2 de obras construídas no Distrito Federal e região</p>
                        </div>
                    </div>
                    <div class="col-md-6 padding-15" style="padding-top: 0px !important;">
                        <div class="service-item box-shadow wow fadeInUp" data-wow-delay="300ms"
                            onmouseover="document.getElementById('img-how-do-i-doing').style.backgroundImage = 'url(' + '../../../assets/img/obra-em-andamento/obra-em-andamento-18.jpg'+ ')'">
                            <i class="flaticon-tanks"></i>
                            <h3>Reformas</h3>
                            <p>Casas, apartamentos comdomínios, clínicas... temos soluções adequadas para cadas
                                caso.</p>
                        </div>
                    </div>
                    <div class="col-md-6 padding-15" style="padding-bottom: 0px !important;">
                        <div class="service-item box-shadow wow fadeInUp" data-wow-delay="400ms"
                            onmouseover="document.getElementById('img-how-do-i-doing').style.backgroundImage = 'url(' + '../../../assets/img/obra-pronta/pronta-12.jpg'+ ')'">
                            <i class="flaticon-refinery"></i>
                            <h3>Incorporação</h3>
                            <p>Entregamos ao mercado edificações prontas para a comercialização.</p>
                        </div>
                    </div>
                    <div class="col-md-6 padding-15 " style="padding-bottom: 0px !important;">
                        <div class="service-item box-shadow wow fadeInUp" data-wow-delay="500ms"
                            onmouseover="document.getElementById('img-how-do-i-doing').style.backgroundImage = 'url(' + '../../../assets/img/obra-pronta/pronta-11.jpg'+ ')'">
                            <i class="flaticon-control-system"></i>
                            <h3>Consultorias</h3>
                            <p>Com a VSA você terá a certeza de um bom negócio.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 sm-padding">
                <div class="box-shadow fadeInLeft" id="img-how-do-i-doing" data-wow-delay="100ms"
                    style="min-height: 100%; background-image: url('../../../assets/img/obra-em-andamento/obra-em-andamento-18.jpg');  background-position: center;   background-size: cover;">
                </div>

            </div>
        </div>
    </div>
</section>
<section class="service-section section-2 padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 sm-padding ">
                <div class="service-content wow fadeInLeft">
                    <h2>Você</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center h-100">
            <div class="col-lg-4 sm-padding">

                <div class="row services-list">
                    <div class="col-md-12 padding-15" style="padding-top: 0px !important;">
                        <div id="arquiteto" class="service-item text-center service-item-active box-shadow wow fadeInUp"
                            [ngClass]="{'service-item-active':activeItem === 'arquiteto'}"
                            (mouseover)="setVisibleItem('arquiteto')" data-wow-delay="100ms">
                            <h3>Arquiteto</h3>

                        </div>
                    </div>
                    <div class="col-md-12 padding-15 show-not-phone" style="padding-top: -25px !important;">
                        <div class="box-shadow you-bg fadeInLeft text-center show-not-phone" id="arquiteto-info-mobile"
                            [ngStyle]="{'display':activeItem === 'arquiteto' ? 'block' : 'none' }"
                            data-wow-delay="100ms" style="padding: 50px;">
                            <div class="show-not-phone">
                                <h3>Projete, crie, liberte-se... valorize o seu cliente. A VSA é a extensão do
                                    seu
                                    escritório, aqui construímos além de tudo, parcerias.</h3>
                                <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn"
                                    target="_blank">
                                    Entre em contato conosco
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15" style="padding-top: 0px !important;">
                        <div id="investidor" class="service-item text-center box-shadow wow fadeInUp"
                            [ngClass]="{'service-item-active':activeItem === 'investidor'}" data-wow-delay="100ms"
                            (mouseover)="setVisibleItem('investidor')">
                            <h3>Investidor</h3>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15 show-not-phone" style="padding-top: -25px !important;">
                        <div class="box-shadow you-bg fadeInLeft text-center show-not-phone" id="investidor-info-mobile"
                            [ngStyle]="{'display':activeItem === 'investidor' ? 'block' : 'none' }"
                            data-wow-delay="100ms" style="padding: 50px;">
                            <div class="show-not-phone">
                                <h3>O setor imobiliário representa a solidez do mercado, encontre na VSA a expertise
                                    necessária para viabilizar os seus empreendimentos.</h3>
                                <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn"
                                    target="_blank">
                                    Entre em contato conosco
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15" style="padding-top: 0px !important;">
                        <div id="pessoa-fisica" class="service-item box-shadow wow fadeInUp text-center"
                            [ngClass]="{'service-item-active':activeItem === 'pessoa-fisica'}" data-wow-delay="100ms"
                            (mouseover)="setVisibleItem('pessoa-fisica')">
                            <h3>Pessoa Física</h3>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15 show-not-phone" style="padding-top: -25px !important;">
                        <div class="box-shadow you-bg fadeInLeft text-center show-not-phone"
                            [ngStyle]="{'display':activeItem === 'pessoa-fisica' ? 'block' : 'none' }"
                            id="pessoa-fisica-info-mobile" data-wow-delay="100ms" style="padding: 50px;">
                            <div class="show-not-phone">
                                <h3>Transformar o seu sonho e patrimônio em realidade é o nosso objetivo. Queremos
                                    conhecer
                                    a sua história e ajudá-lo nessa realização.</h3>
                                <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn"
                                    target="_blank">
                                    Entre em contato conosco
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15 "
                        style="padding-top: 0px !important; padding-bottom: 0px !important;">
                        <div id="pessoa-juridica" class="service-item box-shadow wow fadeInUp text-center"
                            [ngClass]="{'service-item-active':activeItem === 'pessoa-juridica'}" data-wow-delay="100ms"
                            (mouseover)="setVisibleItem('pessoa-juridica')">
                            <h3>Pessoa Jurídica</h3>
                        </div>
                    </div>
                    <div class="col-md-12 padding-15 show-not-phone" style="padding-top: -25px !important;">
                        <div class="box-shadow you-bg fadeInLeft text-center show-not-phone"
                            [ngStyle]="{'display':activeItem === 'pessoa-juridica' ? 'block' : 'none' }"
                            id="pessoa-juridica-info-mobile" data-wow-delay="100ms" style="padding: 50px;">
                            <div class="show-not-phone">
                                <h3>A VSA é uma empresa certificada para exercer suas atividades em diversas áreas:
                                    institucionais, comerciais, médicas... Pautamos o planejamento e eficiência como
                                    métricas de trabalho.</h3>
                                <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn"
                                    target="_blank">
                                    Entre em contato conosco
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" hidden-phone col-lg-8 sm-padding" style="min-height: 150px; ">
                <div class="box-shadow you-bg fadeInLeft text-center" id="arquiteto-info" data-wow-delay="100ms"
                    [ngStyle]="{'display':activeItem === 'arquiteto' ? 'block' : 'none' }"
                    style="min-height: 100%; padding: 50px;">
                    <div class="vertical-center ">
                        <h3>Projete, crie, liberte-se... valorize o seu cliente. A VSA é a extensão do seu
                            escritório, aqui construímos além de tudo, parcerias.</h3>
                        <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn" target="_blank">
                            Entre em contato conosco
                        </a>
                    </div>
                </div>
                <div class="hidden-phone box-shadow fadeInLeft text-center" id="investidor-info" data-wow-delay="100ms"
                    [ngStyle]="{'display':activeItem === 'investidor' ? 'block' : 'none' }"
                    style="min-height: 100%; display: none; padding: 50px;">
                    <div class="vertical-center">
                        <h3>O setor imobiliário representa a solidez do mercado, encontre na VSA a expertise
                            necessária para viabilizar os seus empreendimentos.</h3>
                        <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn" target="_blank">
                            Entre em contato conosco
                        </a>
                    </div>
                </div>
                <div class="hidden-phone box-shadow fadeInLeft text-center" id="pessoa-fisica-info"
                    [ngStyle]="{'display':activeItem === 'pessoa-fisica' ? 'block' : 'none' }" data-wow-delay="100ms"
                    style="min-height: 100%; display: none;padding: 50px;">
                    <div class="vertical-center">
                        <h3>Transformar o seu sonho e patrimônio em realidade é o nosso objetivo. Queremos conhecer
                            a sua história e ajudá-lo nessa realização.</h3>
                        <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn" target="_blank">
                            Entre em contato conosco
                        </a>
                    </div>
                </div>
                <div class="hidden-phone box-shadow fadeInLeft text-center" id="pessoa-juridica-info"
                    [ngStyle]="{'display':activeItem === 'pessoa-juridica' ? 'block' : 'none' }" data-wow-delay="100ms"
                    style="min-height: 100%; display: none;padding: 50px;  ">
                    <div class="vertical-center">
                        <h3>A VSA é uma empresa certificada para exercer suas atividades em diversas áreas:
                            institucionais, comerciais, médicas... Pautamos o planejamento e eficiência como
                            métricas de trabalho.</h3>
                        <a href="https://api.whatsapp.com/send?phone=556133231597" class="default-btn" target="_blank">
                            Entre em contato conosco
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="sponsor-section">
    <div class="container">
        <div class="section-heading mb-40">
            <span>Parceiros</span>
        </div>
        <owl-carousel [options]="partnersCarouselConfig" [carouselClasses]="['sponsor-carousel', 'owl-carousel']">
            <div class="sponsor-item" *ngFor="let partner of partners; let i = index">
                <a href="{{partner.link}}" target="_blank">
                    <img src="{{partner.img}}" alt="{{partner.name}}">
                </a>
            </div>
        </owl-carousel>
        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">
            <div class="sponsor-item">
                <a href="https://sainz-arquitetura.com" target="_blank">
                    <img src="../../../assets/img/parceiros/0.jpeg" alt="SAINZ">
                </a>
            </div>
            <div class="sponsor-item">
                <img src="../../../assets/img/parceiros/1.png" alt="Conceito Iluminação">
            </div>
            <div class="sponsor-item">
                <a href="https://www.instagram.com/esquadroaluminio/?hl=pt-br" target="_blank">
                    <img src="../../../assets/img/parceiros/2.png" alt="Esquadro Aluminio">
                </a>
            </div>
            <div class="sponsor-item">
                <a href="https://aurea.eng.br/" target="_blank">
                    <img src="../../../assets/img/parceiros/3.png" alt="Aurea Engenharia">
                </a>
            </div>
            <div class="sponsor-item">
                <a href="https://seypeengenharia.com.br/" target="_blank">
                    <img src="../../../assets/img/parceiros/4.png" alt="SEYPE Engenharia">
                </a>
            </div>
            <div class="sponsor-item">
                <a href="http://www.vasconcelossantini.com.br/" target="_blank">
                    <img src="../../../assets/img/parceiros/5.png" alt="Vasconselos Santini Arquitetura">
                </a>
            </div>
            <div class="sponsor-item">
                <a href="https://www.caudf.gov.br/" target="_blank">
                    <img src="../../../assets/img/parceiros/6.jpg" alt="Vasconselos Santini Arquitetura">
                </a>
            </div>
            <div class="sponsor-item">
                <a href="https://sinduscondf.org.br/" target="_blank">
                    <img src="../../../assets/img/parceiros/7.jpg" alt="Vasconselos Santini Arquitetura">
                </a>
            </div>
        </div>
    </div>
</div>