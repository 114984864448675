<section class="projects-section bg-grey padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Obras</h1>
            </div>
        </div>
        <div class="row">
            <div id="instafeed"></div>
        </div>
    </div>
</section>

<script src="https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js"></script>