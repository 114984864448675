<div class="mapouter">
    <div class="gmap_canvas"><iframe width="100%" height="350" id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.972575320996!2d-47.89252068504961!3d-15.752589989077197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3a512f439b29%3A0xba25c78d0bdaf616!2sVSA%20CONSTRUTORA!5e0!3m2!1spt-BR!2sus!4v1608819169220!5m2!1spt-BR!2sus"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
            href="http://http://vsaconstrutora.com.br//"></a></div>
    <style>
        .mapouter {
            position: relative;
            text-align: right;
            height: 350px;
            width: 100%;
        }

        .gmap_canvas {
            overflow: hidden;
            background: none !important;
            height: 350px;
            width: 100%;
        }
    </style>
</div>
<section class="contact-section bg-grey padding">
    <div class="container">
        <div class="contact-wrap d-flex align-items-center row">
            <div class="col-md-6 padding-15">
                <div class="contact-info">
                    <h3>CLN 112 BL D Sala 102 <br /> Asa Norte, Brasília - DF</h3>
                    <h4><span>Email:</span> administrativo@vsaconstrutora.com.br </h4>

                        <span>Telefone:</span> <a href="https://api.whatsapp.com/send?phone=556133231597" tabindex="_blank" > (61) 3323-1597 </a><br>
                </div>
            </div>
            <div class="col-md-6 padding-15">
                <div class="contact-form">
                    <form action="" method="post" id="ajax_form" class="form-horizontal">
                        <div class="form-group colum-row row">
                            <div class="col-sm-6">
                                <input type="text" id="name" name="name" class="form-control" placeholder="Nome"
                                    required>
                            </div>
                            <div class="col-sm-6">
                                <input type="email" id="email" name="email" class="form-control" placeholder="Email"
                                    required>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <textarea id="message" name="message" cols="30" rows="5"
                                    class="form-control message" placeholder="Menssagem" required></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <a href="#" id="submit" class="default-btn" type="submit">Enviar</a>
                            </div>
                        </div>
                        <div id="form-messages" class="alert" role="alert"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>