import { AfterContentInit, Component } from '@angular/core';
import Instafeed from 'instafeed.js';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements AfterContentInit {

  constructor() { }

  ngAfterContentInit(): void {
    console.log(Instafeed)
    var feed = new Instafeed({
      accessToken: 'IGQVJWd2hlUkpyWkNVMmpadlItWnRFcXA4akl2NmlLckgzODVac2JxejNPSUtQVGlHbXhLMEpDMEZAFQkI1cG40UkwzdHFNMllpQzFkczVWOFd3WERvZAHRfS1FtSHZAUTmhYbGNGdmN3NGZAfV01HVmVPXwZDZD'
    });

    feed.run();
  }

}
