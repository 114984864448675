import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }
  public menuOpen: boolean = false;
  public menuOpenSticker: boolean = false;
  public menIsActive: boolean = false;

  ngOnInit(): void {
  }

  openCloseMenu(sticker: boolean) {
    if (sticker) {
      this.menuOpenSticker = !this.menuOpenSticker;
    }
    else {
      this.menuOpen = !this.menuOpen;
    }
    this.menIsActive = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.menIsActive = true;
    } else {
      this.menIsActive = false;
    }
  }
}
