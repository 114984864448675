import { Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  public slides = [
    { img: 'assets/img/obra-pronta/pronta-15.jpg' },
    { img: 'assets/img/obra-pronta/pronta-7.jpg' },
    { img: 'assets/img/obra-pronta/pronta-9.jpg' },
    { img: 'assets/img/obra-pronta/pronta-14.jpg' }
  ];
  public slideConfig = {
    'autoplay': true,
    'autoplaySpeed': 9190,
    'dots': false,
    'fade': true,
    'infinite': true,
    'pauseOnFocus': false,
    'prevArrow': '<div class="slick-prev"><i class="fa fa-chevron-left"></i></div>',
    'nextArrow': '<div class="slick-next"><i class="fa fa-chevron-right"></i></div>'
  };
  public testimonialCarouselConfig = {
    loop: true,
    margin: 10,
    center: false,
    autoplay: true,
    smartSpeed: 500,
    nav: false,
    navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 2,
      }
    }
  };
  public testimonials = [
    {
      img: '../../../assets/img/testimonials/ana_paula_felipini_barros.jpg',
      testimonial: `Reformar ou construir muitas vezes pode ser difícil, mas
    quando se tem
    profissionais qualificados e comprometidos o resultado pode ser fantástico.
    Minha experiência tem sido excelente após ter contratado a empresa VSA para duas
    reformas grandes. <br />
    A empresa apresenta soluções e ideias criativas. Os profissionais são
    qualificados e bem-humorados. A obra é limpa e o resultado supera o esperado.
    <br />
    Além disso, cumpre o cronograma e dá assistência pós obra e garantia dos
    serviços.`,
      customer: 'Ana Paula Felipini',
      from: 'Cliente desde 2015 Brasília-Lago Norte novembro/2020'
    },
    {
      img: '../../../assets/img/testimonials/marcelo_conceito.jpg',
      testimonial: `Pela postura e seriedade na escolha de produtos e soluções, que são pautadas em
      compromisso ético e sempre prevalecendo o cliente.`,
      customer: 'Marcelo Miranda',
      from: 'Parceiro e proprietário da empresa Conceito Iluminação Asa Sul novembro/2020'
    },
    {
      img: '../../../assets/img/testi-1.jpg',
      testimonial: `Acho que vou por essa linha: comodidade, rapidez e segurança. Trabalho
      bem feito por gente legal.<br />
      Esse que são os benefícios que o cliente tem, que é o que ele quer e precisa`,
      customer: 'Milton Medeiros Pinto',
      from: 'Cliente desde 2018, lago sul'
    },
    {
      img: '../../../assets/img/testimonials/fabiana_santini.jpg',
      testimonial: `O bom humor da equipe VSA é insuperável, executar obra também é diversão.`,
      customer: 'Fabiana Santini',
      from: 'Arquiteta parceira desde 2009, Lago Norte'
    },
    {
      img: '../../../assets/img/testimonials/iran_siqueira.jpg',
      testimonial: `Ótimas referencias que comprovam: <br />
      Qualidade, competência, presteza, cumprimento de prazos, aproximação e
      integração com o cliente, preços justos, bom relacionamento, equipe bem treinada
      comprometida com o trabalho, qualificada e conhecedora do serviço, além de um
      acompanhamento seguro, profissional e responsável, com execução esmerada. <br />
      A VSA desenvolve projetos modernos e funcionais, com suporte tecnológico
      atualizado para apresentação nas reuniões com os clientes e compartilha as
      soluções apresentadas, com atendimento consensual às solicitações e às
      exigências do cliente, na evolução dos serviços. Destaca-se que no pós obra
      atende com suporte oportuno e pontual aos apontamentos do cliente. Estes são os
      fatores e indicadores que qualificam a VSA e nos levam, na qualidade de clientes
      satisfeitos, a recomendar que a empresa seja contratada.`,
      customer: 'Iran Siqueira',
      from: 'Cliente e amigo, Asa Sul'
    }

  ]

  public partners = [
    {
      link: 'https://sainz-arquitetura.com',
      img: '../../../assets/img/parceiros/0.jpeg',
      name: 'SAINZ'
    },
    {
      link: '#',
      img: '../../../assets/img/parceiros/1.png',
      name: 'Conceito Iluminação'
    },
    {
      link: 'https://www.instagram.com/esquadroaluminio/?hl=pt-br',
      img: '../../../assets/img/parceiros/2.png',
      name: 'Esquadro Aluminio'
    },
    {
      link: 'https://aurea.eng.br/',
      img: '../../../assets/img/parceiros/3.png',
      name: 'Aurea Engenharia'
    },
    {
      link: 'https://seypeengenharia.com.br/',
      img: '../../../assets/img/parceiros/4.png',
      name: 'SEYPE Engenharia'
    },
    {
      link: 'http://www.vasconcelossantini.com.br/',
      img: '../../../assets/img/parceiros/5.png',
      name: 'Vasconselos Santini Arquitetura'
    },
    {
      link: 'https://www.caudf.gov.br/',
      img: '../../../assets/img/parceiros/6.jpg',
      name: 'CAU-DF'
    },
    {
      link: 'https://sinduscondf.org.br/',
      img: '../../../assets/img/parceiros/7.jpg',
      name: 'SINDUSCON-DF'
    }
  ];

  public partnersCarouselConfig = {
    loop: true,
    margin: 5,
    center: false,
    autoplay: true,
    smartSpeed: 500,
    nav: false,
    navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
    dots: false,
    responsive: {
      0: {
        items: 2
      },
      480: {
        items: 3,
      },
      768: {
        items: 3,
      },
      992: {
        items: 6,
      }
    }
  };

  ngOnInit(): void {
  }
  public activeItem: string = 'arquiteto';
  setVisibleItem(item) {

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
      return;
    }

    this.activeItem = item;

  }
}
 