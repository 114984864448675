import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

  public partnersCarouselConfig = {
    loop: true,
    margin: 5,
    center: false,
    autoplay: true,
    smartSpeed: 500,
    nav: false,
    navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
    dots: false,
    responsive: {
      0: {
        items: 2
      },
      480: {
        items: 3,
      },
      768: {
        items: 3,
      },
      992: {
        items: 6,
      }
    }
  };

  public faqs = [{
    group: 'Vantagens do CNPJ',
    icon: 'apartment',
    answers: [{
      question: 'POR QUE CONTRATAR UMA CONSTRUTORA?',
      answer: 'Pela qualidade do serviço, pela garantia do produto, pela continuidade do trabalho, pela segurança financeira, pela nitidez das informações, pelo conhecimento do processo, pelo domínio do mercado imobiliário, pela paridade da comunicação entre a partes envolvidas.'
    },
    {
      question: 'QUAL O DIFERENCIAL DA VSA CONSTRUTORA?',
      answer: 'A valorização do ser humano, sempre!'
    },
    {
      question: 'QUAIS AS GARANTIAS OFERECIDAS PELA VSA?',
      answer: `Prezamos pela qualidade dos produtos, pela melhoria contínua dos processos e temos como objetivo a excelência. Caso haja alguma incidência de garantia, nos fundamentamos no Código Civil Brasileiro e nas normas da ABNT NBR 15575-1/2013, conforme alguns exemplos: 
      <ul>
      <li>Forros de gesso (1 ano: fissuras por acomodação dos elementos estruturais e de vedação)</li>
      <li>Selantes, componentes de juntas e rejuntamentos (1 ano: aderência)</li>
      <li>Pintura/verniz (2 anos: empolamento, descascamento, esfarelamento, alteração de cor ou deterioração de acabamento)</li>
      <li>Instalações hidráulicas e gás coletores/ramais/louças/caixas de descarga/bancadas/metais sanitários/sifões/ligações flexíveis/ válvulas/registros/ralos/tanques (3 anos: instalação)</li>
      <li>Instalações elétricas Tomadas/interruptores/ disjuntores/fios/cabos/eletrodutos/ caixas e quadros (3 anos: instalação)</li>
      <li>Fundações, estrutura principal, estruturas periféricas, contenções e arrimos (5 anos: segurança e estabilidade global, estanqueidade de fundações e contenções)</li>
      </ul>
      `
    },
    {
      question: 'QUAL PROCEDIMENTO PARA SOLICITAR A ASSISTENCIA PARA MEU IMOVEL?',
      answer: 'Envie mensagem para o <a href="https://api.whatsapp.com/send?phone=556133231597" target="_blank">WhatsApp da VSA</a> ou e-mail para <a href="mailto:vsa@vsaconstrutora.com.br">vsa@vsaconstrutora.com.br</a> registrando o chamado de assistência técnica. Em até 48h a analisaremos o chamado. Caso esteja dentro do prazo legal de garantia, encaminharemos a equipe técnica para efetuar vistoria no seu imóvel.'
    },
    {
      question: 'QUAL O HORÁRIO DE ATENDIMENTO DO ESCRITÓRIO?',
      answer: 'Funcionamos em horário comercial das 7h às 12h e de 13h às 17h, de segunda à quinta. Às sextas, funcionamos até às 16h.'
    }]
  }, {
    group: 'Projetos',
    icon: 'map',
    answers: [
      {
        question: 'A VSA CONSTRUTORA FAZ PROJETOS DE ARQUITETURA?',
        answer: 'Temos parcerias com escritórios de arquitetura altamente qualificados que podem atender a essa demanda.'
      },
      {
        question: 'A VSA CONSTRUTORA FAZ PROJETOS COMPLEMENTARES?',
        answer: 'Sim, estamos preparados para entregar um produto completo para os nossos clientes.'
      },
      {
        question: 'O QUE SÃO PROJETOS COMPLEMENTARES? ELES SÃO REALMENTE IMPORTANTES?',
        answer: `São os projetos técnicos que complementam o projeto arquitetônico e devem ser realizados por especialistas em cada área, exemplificados:
        <ul>
        <li>Fundação: feito exclusivamente por engenheiro civil</li>
        <li>Estrutura: pode ser realizado por engenheiro civil ou arquiteto</li>
        <li>Instalações Elétricas, TV, VDI: executado tanto pelo engenheiro civil, eletricista quanto pelo arquiteto</li>
        <li>Instalações Hidrossanitárias: feito pelo engenheiro civil ou arquiteto</li>
        <li>Instalações de ar condicionado, exaustão, elevador: realizado somente pelo engenheiro mecânico</li>
        </ul>
        `
      },
      {
        question: 'É NECESSÁRIO COMPATIBILIZAR PROJETOS COMPLEMENTARES COM O DE ARQUITETURA?',
        answer: 'Sim, na verdade é fundamental. Nessa etapa é que são ajustadas as novas demandas exigidas pelos projetos complementares e suas interferências na arquitetura.'
      }
    ]
  }, {
    group: 'Quanto custa?',
    icon: 'attach_money',
    answers: [
      {
        question: 'É COBRADA TAXA DE VISITA TÉCNICA INICIAL?',
        answer: 'Sim. Valor a ser consultado antes do agendamento. Enviar mensagem para o <a href="https://api.whatsapp.com/send?phone=556133231597" target="_blank">WhatsApp da VSA</a> ou e-mail para <a href="mailto:vsa@vsaconstrutora.com.br">vsa@vsaconstrutora.com.br</a> para maiores esclarecimentos.'
      },
      {
        question: 'A VSA CONSTRUTORA ACEITA FINANCIAMENTO?',
        answer: 'No intuito de viabilizar a construção, podemos trabalhar com obras financiadas por qualquer agente financeiro, seja ele banco, consórcio ou outra modalidade a ser definida.'
      },
      {
        question: 'QUAIS SÃO AS MODALIDADES DE CONTRATO DA VSA?',
        answer: 'Trabalhamos basicamente com duas formas de contratação para os mais diversos serviços: empreitada global e empreitada global com exclusão de materiais de acabamento.'
      },
      {
        question: 'COMO POSSO PAGAR A MINHA OBRA?',
        answer: 'Preferencialmente por pagamentos eletrônicos, por trazerem mais segurança e comodidade aos nossos clientes.'
      },
      {
        question: 'COMO COMPRO UMA CASA PRONTA DA VSA?',
        answer: 'Entregamos ao mercado, periodicamente, produtos muito bem localizados e alinhados com as tendências atuais. Entre em contato que lhe encaminharemos ao nosso parceiro comercial para maiores detalhes.'
      },
      {
        question: 'COMO FAÇO PARA VISITAR A OBRA?',
        answer: 'Para realizar visita o cliente deve enviar mensagem para o <a href="https://api.whatsapp.com/send?phone=556133231597" target="_blank">WhatsApp da VSA</a> ou e-mail para <a href="mailto:vsa@vsaconstrutora.com.br">vsa@vsaconstrutora.com.br</a> e fazer o agendamento.'
      },
      {
        question: 'COMO É CALCULADO O VALOR EXATO DE UMA OBRA?',
        answer: 'O valor total de cada obra é calculado individualmente, levantando o quantitativo de consumo de todos os itens do produto e precificando-os individualmente.'
      },
      {
        question: 'O QUE É CUB? POSSO ESTIMAR O CUSTO DA OBRA POR M²?',
        answer: 'Custo Unitário Básico (CUB) é o principal indicador do setor da construção civil. É calculado mensalmente pelos Sindicatos da Indústria da Construção Civil de todo o país. No DF <a target="_blank" href="https://sinduscondf.org.br/indicadores">SINDUSCONDF</a>'
      },
      {
        question: 'POR QUE O CUB DA MINHA CASA É DIFERENTE DO VALOR DO ORÇAMENTO REAL APRESENTADO?',
        answer: 'O CUB é calculado para determinar o custo de construção de um determinado projeto-padrão e normalmente é usado para estimativas preliminares e aproximadas quando o projeto específico da construção ainda não está perfeitamente definido. Existindo um projeto definido, pode-se determinar seu custo através de orçamentos sintéticos ou por composição de custos. O preço real de um determinado empreendimento engloba o CUB, os demais custos não considerados no cálculo deste e os custos indiretos relacionados ao empreendimento, incluindo a remuneração do construtor e do incorporador. Os custos financeiros e o risco associado ao projeto, que influenciam a margem de lucro do incorporador e do construtor, também são considerados para determinar o preço do produto imobiliário a ser comercializado.'
      }
    ]
  }, {
    group: 'Materiais',
    icon: 'format_paint',
    answers: [
      {
        question: 'OS MATERIAIS UTILIZADOS PELA VSA EM MINHA OBRA SÃO DE BOA QUALIDADE?',
        answer: 'Seguimos sempre as especificações técnicas recomendadas em projeto. Utilizamos materiais de 1A LINHA buscando o melhor custo x benefício. Caso haja necessidade de ajustes, faremos em conjunto com nosso contratante e responsável técnico do projeto ou especificador.'
      },
      {
        question: 'POSSO COMPRAR OS MATERIAIS BÁSICOS PARA MINHA OBRA E SOMENTE CONTRATAR A MÃO DE OBRA DA VSA?',
        answer: 'Não trabalhamos com essa forma de contratação. Nossa expertise na área de compra e logística destes materiais, traz maior agilidade aos serviços e tranquilidade ao nosso contratante.'
      },
      {
        question: 'O QUE SÃO MATERIAIS DE ACABAMENTO?',
        answer: 'É tudo que o cliente e/ou arquiteto podem escolher, são definidos como o grupo de gosto, exemplificados: tintas, louças, metais, revestimentos, iluminação, interruptores, bancadas.'
      },
      {
        question: 'O QUE SÃO MATERIAIS BÁSICOS?',
        answer: 'Materiais básicos são aqueles que a compra é definida pela técnica e não pelo gosto, exemplificados: agregados, aglomerantes, formas, conduítes, conexões, condutores, caixas, tijolos, blocos ou painéis para vedação ou divisão, sistemas de cobertura, esquadrias.'
      }
    ]
  }, {
    group: 'Administrativas',
    icon: 'account_tree',
    answers: [
      {
        question: 'A VSA CONSTRUTORA RESPONSABILIZA-SE PELA APROVAÇÃO DO PROJETO, ALVARÁ DE CONSTRUÇÃO E HABITE-SE?',
        answer: 'Sim, diante de três premissas: terreno regular, projetos elaborados por profissionais habilitados e dentro das normativas vigentes (LUOS) e a construção executada conforme projeto.'
      },
      {
        question: 'O QUE É ALVARÁ DE CONSTRUÇÃO',
        answer: 'O Alvará de Construção é o documento que autoriza a execução da obra no âmbito do Distrito Federal. As obras no Distrito Federal só poderão ser iniciadas após a obtenção do Alvará de Construção'
      },
      {
        question: 'O QUE É HABITE-SE?',
        answer: 'O Habite-se é a certidão do imóvel, emitida pela administração publica, atestando que o empreendimento está apto para ser habitado e foi construído conforme às exigências legais.'
      },
      {
        question: 'O QUE É AVERBAÇÃO DO HABITE-SE?',
        answer: 'Fazer constar ou registrar na certidão de matrícula do empreendimento/ imóvel (documento de cartório) que foi expedido o habite-se pela administração publica, nesta etapa é necessária apresentação da certidão nada consta da obra, emitida pela receita federal.'
      },
      {
        question: 'COMO OBTER O NADA CONSTA DA OBRA JUNTO A RECEITA FEDERAL',
        answer: 'Imediatamente após a emissao do ALVARÁ DE CONSTRUÇÃO, a obra deve ser cadastrada no CNO (Cadastro Nacional de Obras). Esse cadastro tem por finalidade a inscrição de obras de construção civil de pessoas físicas e jurídicas obrigadas ao recolhimento de contribuições previdenciárias instituídas pela Lei nº 8.212, de 24 de julho de 1991'
      },
      {
        question: 'A CONSTRUTORA EMITE NOTAS FISCAIS DOS TRABALHOS EXECUTADOS',
        answer: 'Sempre! É obrigação de toda empresa emitir Notas fiscais pelos serviços prestados.'
      }
    ]
  }];

  public filteredFaqs = [];
  constructor() {
    this.filteredFaqs = [...this.faqs];
  }

  ngOnInit(): void {
  }


  applyFilter(filter) {
    filter = filter.trim();
    this.filteredFaqs = [...this.faqs];
    if (filter) {
      this.filteredFaqs = this.filteredFaqs.filter((faq) =>
        faq.group.toLowerCase().includes(filter.toLowerCase()) ||
        faq.answers.filter((answer) =>
          answer.answer.toLowerCase().includes(filter.toLowerCase()) ||
          answer.question.toLowerCase().includes(filter.toLowerCase())).length > 0)
        .map(faq => {
          let answers = faq.answers.filter((answer) =>
          (answer.answer.toLowerCase().includes(filter.toLowerCase()) ||
            answer.question.toLowerCase().includes(filter.toLowerCase())));
          if (answers.length !== 0) {
            return {
              group: faq.group,
              icon: faq.icon,
              answers
            }
          }
          return faq;
        });
    }
  }

}
