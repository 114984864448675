<header class="header" id="header">
    <div class="primary-header">
        <div class="container">
            <div class="primary-header-inner">
                <div class="header-logo">
                    <a routerLink="/"><img src="../../../assets/img/logo.png" alt="VSA Construtora"></a>
                </div>
                <div class="header-menu-wrap" [ngStyle]="{'display': !menuOpen && menIsActive ? 'none' : 'block', 'margin-top':  menuOpen && menIsActive ? '53px' : '0px'}">
                    <ul class="dl-menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/projects">Obras</a>
                        </li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/contact">Contato</a></li>
                        <li><a class="menu-btn" routerLink="/customer-area">Área do cliente</a></li>
                    </ul>
                </div>
                <div class="header-right">
                    <div class="mobile-menu-icon">
                        <div class="burger-menu" (click)="openCloseMenu(false)" [ngClass]="menuOpen ? 'burger-menu menu-open' : 'burger-menu'">
                            <div class="line-menu"></div>
                            <div class="line-menu"></div>
                            <div class="line-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<ngx-headroom class="sticky-header">
    <div class="primary-header">
        <div class="container">
            <div class="primary-header-inner">
                <div class="header-logo">
                    <a routerLink="/"><img src="../../../assets/img/logo.png" alt="VSA Construtora"></a>
                </div>
                <div class="header-menu-wrap" [ngStyle]="{'display': menuOpenSticker ? 'block' : menIsActive ? 'none' : 'block'}">
                    <ul class="dl-menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/projects">Obras</a>
                        </li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/contact">Contato</a></li>
                        <li><a class="menu-btn" routerLink="/customer-area">Área do cliente</a></li>
                    </ul>
                </div>
                <div class="header-right">
                    <div class="mobile-menu-icon">
                        <div class="burger-menu"  (click)="openCloseMenu(true)" [ngClass]="menuOpenSticker ? 'burger-menu menu-open' : 'burger-menu'">
                            <div class="line-menu"></div>
                            <div class="line-menu"></div>
                            <div class="line-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ngx-headroom>